import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useState } from 'react';
import { $path } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { GamePackBreadcrumbs } from '../../app/components/GamePack/GamePackBreadcrumbs';
import { useHydratedNumPerRow } from '../../app/components/GamePack/GamePackCollection';
import {
  type GameLikeFilterOptions,
  toGameLikeFilters,
} from '../../app/components/GamePack/GamePackFilters';
import { HomePublicLibraryNav } from '../../app/components/PublicLibrary/HomePublicLibraryNav';
import {
  encodeFilterOptions,
  parseFilterOptions,
  PublicLibraryFilter,
  PublicLibraryFilterKeysAllLoggedIn,
} from '../../app/components/PublicLibrary/PublicLibraryFilter';
import { useGameLikeWorkspace } from '../components/Game/GameCenter';
import {
  DefaultGamePackCardBadges,
  GamePackCard,
  MyGamePackBadge,
} from '../components/Game/GamePack/GamePackCard';
import { PublicHomeLayoutV2 } from '../components/PublicHome/PublicHomeLayout';
import { GamePackCardBottomAccessory } from '../pages/GamePack/GamePackCardBottomAccessory';
import { apiService } from '../services/api-service';
import { getGridStyle } from '../utils/css';
import { usePublicHomeData } from './home.client';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const params = new URL(action.request.url).searchParams;
  const filterOptions = parseFilterOptions(params);
  const q = params.get('q') || '';

  const packs = await apiService.gamePack
    .searchGamePacks(q, {
      filterParams: toGameLikeFilters(filterOptions),
      primeOnly: true,
      scope: EnumsPageName.PageNamePublicHome,
    })
    .next();

  return {
    q: params.get('q'),
    filterOptions,
    packs,
  };
}

export function Component() {
  const { pageName, page } = usePublicHomeData();
  const { filterOptions, packs } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  const handleApply = (options: Partial<GameLikeFilterOptions> | null) => {
    if (!options) return;

    const params = encodeFilterOptions(options, {
      init: new URLSearchParams(window.location.search),
    });
    navigate({
      search: params.toString(),
    });
  };

  const numPerRow = useHydratedNumPerRow();
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const [filters, setFilters] =
    useState<Partial<GameLikeFilterOptions>>(filterOptions);

  return (
    <PublicHomeLayoutV2
      secondaryNav={<HomePublicLibraryNav pageName={pageName} page={page} />}
    >
      <div className='w-full p-10 flex flex-col gap-10'>
        <GamePackBreadcrumbs
          items={[
            { label: 'Explore', to: $path('/home') },
            { label: 'Matched Experiences' },
          ]}
        />

        <div className='w-full flex justify-center'>
          <PublicLibraryFilter
            showMenuKeys={PublicLibraryFilterKeysAllLoggedIn}
            filterOptions={filters}
            onChange={setFilters}
            onApply={handleApply}
          />
        </div>

        {packs.length > 0 ? (
          <div
            className={`w-full grid gap-y-12 gap-x-3 pt-4 ${
              numPerRow > 0 ? 'visible' : 'invisible'
            }`}
            style={{ gridTemplateColumns: getGridStyle(numPerRow) }}
          >
            {packs.map((p) => (
              <GamePackCard
                key={p.id}
                gamePack={p}
                onClick={(pack) => setActiveGamePack(pack)}
                badges={
                  <>
                    <MyGamePackBadge gamePack={p} />
                    <DefaultGamePackCardBadges gamePack={p} />
                  </>
                }
                bottomAccessory={<GamePackCardBottomAccessory pack={p} />}
                styles={{
                  size: 'w-full',
                }}
                showVersion={false}
              />
            ))}
          </div>
        ) : (
          <div className='text-secondary text-center'>
            No Matching Experiences Found
          </div>
        )}
      </div>
    </PublicHomeLayoutV2>
  );
}
